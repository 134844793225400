import reactL from "../assets/img/reactlogo.svg";
import htmlL from "../assets/img/htmllogo.svg";
import cssL from "../assets/img/csslogo.svg";
import javascriptL from "../assets/img/javascriptlogo.svg";
import pythonL from "../assets/img/pythonlogo.svg";
import postgresqlL from "../assets/img/postgresqllogo.svg";
import djangoL from "../assets/img/djangologo.svg";
import golang from "../assets/img/golang.svg"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.webp"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
	  		<p>These are some of my key skills and tools for solving a problem.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
			    <div className="item">
                                <img src={htmlL} alt="Image" />
                                <h5>HTML</h5>
                            </div>
			    <div className="item">
                                <img src={javascriptL} alt="Image" />
                                <h5>Javascript</h5>
                            </div>
			    <div className="item">
                                <img src={pythonL} alt="Image" />
                                <h5>Python</h5>
                            </div>
                            <div className="item">
                                <img src={cssL} alt="Image" />
                                <h5>CSS</h5>
                            </div>
                            <div className="item">
                                <img src={postgresqlL} alt="Image" />
                                <h5>PostgreSQL</h5>
                            </div>
                            <div className="item">
                                <img src={reactL} alt="Image" />
                                <h5>React</h5>
                            </div>
                            <div className="item">
                                <img src={djangoL} alt="Image" />
                                <h5>Django</h5>
                            </div>
                            <div className="item">
                                <img src={golang} alt="Image" />
                                <h5>Go</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
